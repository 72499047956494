import React, { Component } from 'react';
import Layout from '../components/layout';
import Intro from '../components/mask';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import IconBullet from '../components/iconbullet'
import FaqItem from '../components/faqItem'
import { MDBRow, MDBCol, MDBContainer, MDBIcon, MDBBtn} from 'mdbreact'
import wd_badge from "../images/badge.png"

class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Home" keywords={[`gatsby`, `MDBReact`, `react`, `Material Design For Bootstrap`]} />
        
        <main>
          
          <section id="faq">
          <h1 className="h1-responsive font-weight-bold my-5 text-uppercase text-center">
              Frequently Asked Questions:
            </h1> 
            <FaqItem title="What does a marriage celebrant do?">
              <p>An authorised Marriage Celebrant is appointed by the Federal Government to solemnise marriages (make it legally recognised) according to the Marriage Act 1961. They may solemnise marriages anywhere in Australia including outside the State or Territory in which they reside.</p>
              <p>Celebrants do ongoing professional development each year and are bound by a code of practice.</p>
              <p>Celebrants have three responsibilities when solemnising a marriage:
              <ul>
                <li>Completion and lodgement of all the legal paperwork</li>
                <li>Performing the ceremony, including ensuring the compulsory legal components are recited and witnessed</li>
                <li>Ensuring there is adequate sound at the ceremony</li>
              </ul>
              </p>
              <p>What is included in the ceremony side of things (i.e. fixed or customised ceremony wording, help with ceremony ideas, assistance to write vows, conducting a rehearsal, officiating at the ceremony etc.) varies between celebrants and between the packages they offer. For more information on what I offer visit my Services Page.</p>
            </FaqItem>

            <FaqItem title="What are the legal requirements for marriage in Australia?">
              <p>Marriage is regulated by the Marriage Act 1961 (Cth) and the Marriage Regulations 1963 (Cth). All marriages in Australia must be conducted in accordance with this legislation. The legislation specifies the requirements for the marriage ceremony as well as matters that arise before and after the ceremony.</p>
              <p>The Marriage Act defines marriage as ‘the union of two people to the exclusion of all others, voluntarily entered into for life’. </p>

              <p>To be legally married in Australia, two people must:
              <ul>
                <li>not be married to someone else</li>
                <li>not be marrying a parent, grandparent, child, grandchild, brother or sister</li>
                <li>be at least 18 years old, unless a court has approved a marriage where one party is aged between 16 and 18 years old</li>
                <li>lodge a Notice of Intended Marriage (NOIM) with their celebrant no less than one month and no more than 18 months before their ceremony</li>
                <li>understand what marriage means and freely consent to being married</li>
                <li>sign a declaration stating that there are no legal impediments to the marriage (e.g. already married, underage, too closely related etc.) as close as possible to the ceremony date</li>
                <li>use specific words during the ceremony (the ‘monitum’ spoken by the celebrant and ‘the vows’ spoken by each party to marriage)</li>
                <li>have two witnesses at the ceremony who are at least 18 years of age who will be required to sign the marriage certificates</li>
                <li>ensure their celebrant registers the marriage with the Office of Births, Deaths and Marriages in the state or territory where the ceremony took place within 14 days</li>
              </ul>
              </p>
              <p>You don’t have to be an Australian citizen or a permanent resident of Australia to legally marry here.</p>

              <h5>The ‘Monitum’</h5>
              <p>The word monitum is latin for warning. A monitum is a warning and authorised celebrants are required to recite a prescribed monitum before the vows in a marriage ceremony to explain the nature of the marriage relationship.</p>
              <p>As your authorised celebrant I must say:</p>
              <p><em><strong>"I am duly authorised by law to solemnise marriages according to law. Before you are joined in marriage in my presence and in the presence of these witnesses, I am to remind you of the solemn and binding nature of the relationship into which you are now about to enter. Marriage, according to law in Australia, is the union of two people to the exclusion of all others, voluntarily entered into for life."</strong></em></p>
              <p>There is some (albeit limited) capacity to change certain words. I can provide you with further information if you require.</p>

              <h5>The Vows</h5>
              <p>For a civil ceremony to be a legal marriage ceremony each party must say to the other, in the presence of the authorised celebrant and the witnesses, the words:</p>
              <p><em><strong>”I call upon the persons here present to witness that I, {'{'}insert full name{'}'}, take thee, {'{'}insert partners full name{'}'}, to be my lawful wedded wife/husband/spouse”</strong></em></p>
              <p>These are the minimum words that must be exchanged by you to ensure that you fully understand the nature of the ceremony and that you are marrying each other.</p>
              <p>There is some (albeit limited) capacity to change certain words. I can provide you with further information if you require.</p>
            </FaqItem>

            <FaqItem title="How much notice must be given to be married in Australia?">
              <p>Section 42 of the Marriage Act 1961 (Cth) requires the parties to an intended marriage to give the authorised celebrant at least one month’s written notice prior to the solemnisation of the marriage. This notice is known as the Notice of Intended Marriage (NOIM).</p>
              <p>The NOIM must be given to the authorised celebrant no earlier than 18 months and no later than one month before the date of the marriage. A notice expires after 18 months, and a marriage may not be solemnised if the NOIM was received more than 18 months before the date of the proposed marriage.</p>
            </FaqItem>

            <FaqItem title="What if I want to get married within the next month?">
              <p>You can apply to a prescribed authority for a ‘shortening of time’. If approved your celebrant can be given authorisation to solemnise your marriage despite not meeting the required one month lodgement.</p>
              <p>A list of prescribed authorities is published on the Attorney-General’s Department website https://marriage.ag.gov.au/stateofficers/authorities</p>
              <p>What circumstance will the prescribed authority consider a shortening of time?</p>
              <p>These are limited to:
              <ul>
                <li>employment related or other travel commitments</li>
                <li>wedding or celebration arrangements, or religious considerations</li>
                <li>medical reasons</li>
                <li>legal proceedings, or</li>
                <li>an error in giving notice.</li>
              </ul>
              </p>
            </FaqItem>
            <FaqItem title="What documents and identification do I need to provide the celebrant?">
              <ul>
                <li>Evidence of date and place of birth e.g. original birth certificate or birth extract, passport</li>
                <li>Photographic evidence of identity e.g. Drivers licence, passport, proof of age card</li>
                <li>Evidence of the end of any previous marriage/s e.g. divorce certificate, death certificate</li>
              </ul>
              <p>Ideally these documents should be sighted at the time of lodging the Notice of Intended Marriage (NOIM) with me (the celebrant). </p>
              <p>In some exceptional cases a Statutory Declaration may be acceptable. If you cannot provide any of the above please discuss with me to determine your options.</p>
            </FaqItem>
            <FaqItem title="Can we use legal paperwork that is in another language?">
              <p>No. Before you can submit it, your paperwork will need to be translated into the English language by an accredited translator. See www.naati.com.au for information on translation.</p>
            </FaqItem>
            <FaqItem title="How do we plan our ceremony if we live interstate or overseas?">
            <p>Technology is your best friend if you want to plan a ceremony with me from afar.</p>

              <p>If you reside interstate or overseas the process is as follows:
              <ol>
                <li>Initial contact with me is usually via email, phone or Skype. I will answer all the questions you have, we will discuss your vision for your ceremony, and if you decide that I am the right celebrant for you I will forward you a Booking Form to complete and once a 30% deposit and signed contract have been received the process will begin.</li>
                <li>You need to complete the Notice of Intented Marriage (NOIM) at least one month prior to your wedding and no more than 18 months prior. It must be activated by at least one signature at the time of lodgement and can be witnessed where you live and scanned and emailed to me, or posted to me to meet the time frame. Those eligible to witness are listed on page 4 of the NOIM. If emailed the original document must be presented when we meet.</li>
                <li>Evidence of date and place of birth, photographic identity documents, and evidence of previous marriages must be scanned and emailed to me with the NOIM or certified copies posted to me. The originals must be presented when we meet.</li>
                <li>In order to develop your ceremony there will be lots of back and forth contact between you and I via whatever channel is most convenient (phone, email or Skype™). Contact is unlimited so please don’t hesitate; I planned my overseas wedding in Bali from Australia so I know what it is like to plan a wedding from afar and communication is vital.</li>
                <li>I always strongly recommend rehearsals for ceremonies where I have never met the couple. Things can get lost in translation when only communicated electronically so a face to face meeting or rehearsal before the big day will ensure we are all on the same page. It will also be the perfect opportunity for me to sight original documents and for you to complete the second bit of legal paperwork the Declaration of No Legal Impediment to Marriage.</li>
                <li>I will present you with a Certificate of Marriage on your wedding day following the ceremony. This is only a commemorative certificate. You must apply for an official certificate of marriage from the office of Births Deaths and Marriages in the state/territory where the marriage was solemnised.</li>
                <li>Following your wedding I will lodge your documents with the Office of Births Deaths and Marriages in order to register your marriage.</li>
                <li>If you are not from Australia you will need to seek advice from your relevant Embassy regarding what documents are required to register your Australian marriage in your country. Often you will need to obtain an Apostil stamp on your official marriage certificate from The Department of Foreign Affairs.</li>
              </ol>
              </p>
            </FaqItem>

            <FaqItem title="Can we re-marry in Australia after being married in another country?">
              <p>Marriages entered into overseas are generally recognised as valid in Australia under the following conditions:</p>
              <ul>
                <li>if the marriage was recognised as valid under the law of the country in which it was entered into, at the time when it was entered into, and</li>
                <li>providing the marriage would have been recognised as valid under Australian law if the marriage had taken place in Australia.</li>
              </ul>
              <p>So if this applies to you then the answer is NO. The only type of ceremony you would be able to have is a Vow Renewal or a Commitment Ceremony, neither of which are considered ‘marriage’ under the Australian Marriage Act 1961 (Cth)</p>
            </FaqItem>

            <FaqItem title="Can we personalise our ceremony?">
              <p>Absolutely and I am here to guide you every step of the way. If you book my Bespoke Wedding Package then together we will create a totally unique and special ceremony that truly reflects you, and is memorable for you and your guests for all the right reasons.</p>
              <p>This offer also applies to Commitment Ceremonies and Vow Renewal but does not apply to my other packages.</p>
            </FaqItem>

            <FaqItem title="How many meetings can we have/do we need?">
              <p>I am available to meet with you as often as you need in order to craft your perfect ceremony. As your celebrant I’m here to support and guide you throughout the planning process (I love planning almost as much as the actual day!). Getting to know you through phone, email and face-to-face contact is how I craft a really special and memorable ceremony that is totally personalised. So seriously, contact me as often as you need.</p>
            </FaqItem>

            <FaqItem title="What is the general order of ceremony?">
              <p>For a Bespoke Wedding the order of ceremony is completely up to you. There is nothing I enjoy more than designing something a little different, that still meets the legal requirements, but has your guests talking and engaged because is varies from ‘the norm’.</p>
              <p>However just as a guide ‘the norm’ is basically this:</p>

              <h5>Introduction</h5>
              <p>Celebrant welcomes all the guests and provides any applicable housekeeping announcements (phones on silent, ‘unplugged’ info, what’s happening after the ceremony – group photo etc.)</p>

              <h5>Processional</h5>
              <p>Traditionally the bride walks down the aisle to music with the bridal party and her nominated escort (father, mother, parents etc.).  However every wedding and every couple is unique so the sky is the limit with how you choose, if you choose, to do the processional.  You might want to walk down the aisle together, or you may not want a processional. The choice is totally yours.</p>

              <h5>Giving Away</h5>
              <p>If desired, the bride/groom can be given away. Traditionally the father does the giving away but it can be the mother, both parents, a sibling, a friend…anyone! An alternative option here is a family blessing/acknowledgement, where the parents of the couple are asked to confirm their love and support of the marriage.</p>

              <h5>Welcoming and Your Story</h5>
              <p>The celebrant welcomes everyone, talks about love, the meaning of marriage and the couple’s story. The welcoming can include any particular thanks (parents, people who have travelled) and acknowledgements (loved ones who are no longer with us or absent family and friends).</p>

              <h5>Reading</h5>
              <p>The couple may choose to include some readings or poems. These can be read either by the celebrant or a guest, or even the couple.</p>

              <h5>Rituals/Blessings</h5>
              <p>The couple may include any particular wedding rituals or blessings. These can be traditional, religious or multicultural.</p>

              <h5>Monitum</h5>
              <p>The legal wording from the Marriage Act 1961, stated by the celebrant. It reads as follows:</p>

              <p><em><strong>‘I am duly authorised by law to solemnise marriages according to law. Before you are joined in marriage in my presence and in the presence of these witnesses, I am to remind you of the solemn and binding nature of the relationship into which you are now about to enter. Marriage, according to law in Australia, is the union of a man and a woman to the exclusion of all others, voluntarily entered into for life.’</strong></em></p>

              <h5>The Asking</h5>
              <p>This is where the celebrant asks the couple to confirm their intentions to marry. It’s the non-legal part that ends with the couple saying “I do!”or “I will!”</p>

              <h5>Vows</h5>
              <p>The couple state their vows to each other (including the legal minimum vows from the Marriage Act 1961). The legal minimum is as follows:</p>

              <p><em><strong>”I call upon the persons here present to witness that I, {'{'}insert full name{'}'}, take thee, {'{'}insert partners full name{'}'}, to be my lawful wedded wife/husband/spouse”</strong></em></p>

              <h5>Ring Exchange</h5>
              <p>The couple usually exchange rings, often accompanied by a short ring verse.</p>

              <h5>The Declaration and The Kiss</h5>
              <p>The celebrant concludes and announces the couple as husband and wife / partners for life / wife and wife / husband and husband, etc. If the couple would like they can kiss!</p>

              <h5>Signing of the Register</h5>
              <p>The couple sign their wedding documents with their celebrant and two witnesses.</p>

              <h5>Presentation</h5>
              <p>The celebrant presents the newly married couple, usually as “Mr and Mrs [insert surname]” , or the “newly married couple”.</p>

              <h5>Recessional</h5>
              <p>The couple walk back down the aisle to music, sometimes followed by their bridal party.</p>
            </FaqItem>

            <FaqItem title="How long is ceremony?">
              <p>How long is a piece of string? If you have chosen my Bespoke Wedding Package then the exact length of your wedding ceremony will depend first and foremost on how many different elements you choose to include. As a guide though I would allow roughly 30 minutes from start (arrival of wedding party) to finish (signing certificates followed by congratulations by guests).</p>
            </FaqItem>

            <FaqItem title="When will you send me a first draft of my ceremony?">
              <p>In order to fully personalise your ceremony I ask that you complete a Relationship Questionnaire that I will provide to you just before our initial meeting. Just as soon as you complete and submit this I will get to work on crafting your ceremony.</p>
              <p>Ideally I will send you your first draft within a couple of weeks of our initial meeting while all the ideas you discussed are still fresh. This will allow you plenty of time to personalise it further and add your own ideas to make it totally unique.</p>
              <p>I like to try and finalise ceremonies at least 2 months prior to the wedding date as I know just how flat out couples are finalising all the other details of their wedding the closer the day gets. So the earlier in the planning phase we can work on and finalise the ceremony the better.</p>
            </FaqItem>

            <FaqItem title="During the ceremony where do we stand?">
              <p>There is no legal or official ‘brides side’ and ‘grooms side’ in weddings in Australia. However in saying this it is most common for the bride to stand on the left and the groom to the right. You and your wedding party are free to stand where and how you want (heck you could sit if you like), but here are a few things to keep in mind when trying to decide what is going to work for you:</p>
              <ul>
                <li>If there is a bridal party would you like them standing to the side of their respective bride and groom or would you like them all to one side?</li>
                <li>Would you prefer me (the celebrant) to stand between you or off to one side or a bit of both depending on which part of the ceremony is being performed?</li>
                <li>You need to feel comfortable standing together in front of a crowd so consider what is a natural stance for you as a couple</li>
                <ul>
                  <li>Facing each other</li>
                  <li>Facing the celebrant</li>
                  <li>Facing the audience</li>
                </ul>
                <li>Are you hand holders?</li>
                <li>What looks best in photographs taking into consideration the venue, symmetry, bridal party etc?</li>
                <li>What is going to feel the most special, intimate and the least awkward for you?</li>
              </ul>
            </FaqItem>

            <FaqItem title="Should we have a Rehearsal?">
              <p>The decision to have a rehearsal is totally up to you and I am happy to accommodate your decision either way. I didn’t have a rehearsal per se for my wedding but I did have a catch-up at the venue to suss out things like where the ceremony would be held, where the aisle was an how I would be arriving to walk down it, who would be operating the music and how, where the signing table would be etc. It also gave my bridal party the opportunity to get a feel for the venue before the big day and this helped settle nerves, especially those of the flower girls.</p>
              <p>So no I don’t think it is necessary to run through the ceremony from start to finish like a well rehearsed play but it is certainly a good idea to have a meeting in the weeks leading up to the wedding to step through the details and answer any questions people might have. It is also the time that I generally get you to sign the second bit of legal paper, the Declaration of No Legal Impediment to Marriage.</p>
            </FaqItem>

            <FaqItem title="How many weddings do you book in one day?">
              <p>Generally the most I will book in a day is 2. However for this to occur both weddings would need to either be in the same geographical location (i.e. Barossa Valley) or one of the venues in metropolitan Adelaide, and they would need to be at different times of the day. This is why it is important to know the venue and time of your wedding when enquiring about availability. It is also a factor to be considered in the event you need to change the date, place and or time of your ceremony.</p>
            </FaqItem>

            <FaqItem title="What if we need to change the date / location / time of our ceremony?">
              <p>If your situation changes and I am available, then all of the fees paid are transferrable; however there may be a revision of the contract price.  If I am not available then I’m happy to recommend other celebrants but your deposit of 30% is not refundable.</p>
            </FaqItem>

            <FaqItem title="What is your cancellation policy?">
              <p>A deposit of 50% is payable immediately upon booking and ensures I will be available to perform your ceremony on the agreed date, place and time. In the event of cancellation of the ceremony, this fee is non-refundable.</p>
              <p>If I am unable to perform your ceremony for any reason whatsoever including due to an unforseen accident and/or injury, I will advise you as soon as practicable and make reasonable arrangements for another celebrant to perform the ceremony.</p>
              <p>My full cancellation policy is outlined in the contract you will be asked to sign upon booking.</p>
            </FaqItem>

            <FaqItem title="Do you have a backup celebrant for illness or emergencies?">
              <p>In the event that I am not available to conduct your ceremony due to illness or other emergency, I have access to a wide network of celebrants across Adelaide who I can call upon to step in.</p>
            </FaqItem>

            <FaqItem title="What sound system do you use?">
              <p>I use a Chiayo Stage Pro Multifunctional Wireless Mixer Amplifier System (150W) that delivers excellent sound quality to several hundred people via tripod mount. It includes a CD/USB/SD/Digital video player modules and a digital recorder/player module for playing recorded music during the ceremony. There is also two 6.3mm jacks for up to two instrument level inputs if you have musicians playing live music that would like to use my system for amplification. I use a headset microphone and I provide a hand-held wireless microphone for the vows and any readings by guests.</p>
            </FaqItem>
          </section>
          
        </main>
        </Layout>
      </>
    );
  }
}

export default App;
