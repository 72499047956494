import React, { Children, Component } from 'react'
import { MDBCol, MDBRow, MDBCollapse, MDBContainer, MDBCard, MDBCardHeader, MDBCardBody, MDBIcon} from 'mdbreact';
import './faqItem.css'

class FaqItem extends Component {
    state = {
        collapseID: ""
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
      }

    render() {
    return (
        
        <MDBContainer>
        
        <MDBCard>
            <MDBCardHeader onClick={this.toggleCollapse("basicCollapse")}>
                <MDBRow>
                    <MDBCol size="10">
                    <h5>{this.props.title}</h5>
                    </MDBCol>
                    <MDBCol size="2">
                    <MDBIcon  icon={this.state.collapseID ? "caret-up" : "caret-down" } size="2x" className="float-right grey-text"/>
                    </MDBCol>
                </MDBRow>
                
                
                
            </MDBCardHeader>
            
            <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
            <MDBCardBody>
                {this.props.children}
            </MDBCardBody>
            </MDBCollapse>
        </MDBCard>
        </MDBContainer>
        );
    }
}
      
export default FaqItem;


// const IconBullet = ( props ) => {
//     return (
//         <MDBRow className="py-1">
//             <MDBCol size="2">
//             <MDBIcon far={props.far} fab={props.fab} icon={props.icon} size="2x" className="cyan-text pr-3"/>
//             </MDBCol>
//             <MDBCol size="10">
//             <p>{props.children}</p>
            
//             </MDBCol>
//         </MDBRow>
//     )
// }

// export default IconBullet